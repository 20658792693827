@use '../../common/styleConstants.scss' as *;

.analysisWithoutStockingSection {
  .ant-table-tbody {
    tr {
      td {
        cursor: default;
      }
    }

    >tr.ant-table-row {
      &:hover {
        td {
          background: transparent;
        }
      }
    }
  }
}
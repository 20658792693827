@use '../../common/styleConstants.scss' as *;

.statusCell {
  display: flex;
  width: 100%;
  margin-top: -5px;
  margin-bottom: -5px;
}

.clients {
  width: 100%;
  margin-top: -15px;
  padding: 0 15px;

  .rowFlex {
    display: flex;
    flex: 1;

    .tableClients :global {
      .ant-table .ant-table-container .ant-table-body {
        height: calc(100vh - 235px);
      }

      .ant-table.ant-table-empty .ant-table-container {
        tbody.ant-table-tbody {
          height: calc(100vh - 235px);
        }
      }
    }
  }
}

.searchOutlinedIconDark svg {
  fill: $title-color-dark;
}

.searchOutlinedIconDark:hover svg {
  fill: $chinese-silver;
}

.searchOutlinedIconLight svg {
  fill: $silver-chalice;
}

.searchOutlinedIconLight:hover svg {
  fill: $title-color-light;
}

.menuSearch {
  background-color: $body-background-light;
  border-radius: 5px;
  padding: 8px;

  .inputSearch {
    width: 188px;
    margin-bottom: 8px;
    display: block;
  }

  .buttonSearch {
    width: 90px;
    border-radius: 5px;
  }

  .buttonReset {
    border: 1px solid $title-color-light;
    background-color: transparent;
    border-radius: 5px;
    color: $title-color-light;
    width: 90px;

    &:hover {
      background-color: $chinese-white;
    }
  }
}

.clickToToCopyActive {
  color: $primary-color-dark;
}

.optionsCell {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.menuItemOptions {
  span {
    display: flex;
    align-items: center;

    i::before {
      margin-right: 2px;
    }
  }

  .icon {
    color: $top-bar-icon;
  }
}
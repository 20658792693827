.transportTable {
  .ant-table-body {
    height: calc(100vh - 210px);
  }

  .ant-table.ant-table-empty .ant-table-container {
    tbody.ant-table-tbody {
      height: calc(100vh - 210px);
    }
  }
}
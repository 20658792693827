@use '../../../common/styleConstants.scss' as *;

.historicalBalanceTable {
  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        .ant-table-body {
          height: calc(100vh - 248px) !important;
          overflow-y: scroll !important;
        }
      }
    }

    .ant-table.ant-table-empty .ant-table-container {
      tbody.ant-table-tbody {
        height: calc(100vh - 248px);
      }
    }
  }
}
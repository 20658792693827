@use '../../common/styleConstants.scss' as *;

.analysisSection {
  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        .ant-table-body {
          height: calc(100vh - 240px);
          overflow-y: scroll !important;
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        cursor: default;
      }
    }

    >tr.ant-table-row {
      &:hover {
        td {
          background: transparent;
        }
      }
    }
  }
}

.analysisSectionWithParameterOptions {
  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        .ant-table-body {
          height: calc(100vh - 300px);
        }
      }
    }

    .ant-table.ant-table-empty .ant-table-container {
      tbody.ant-table-tbody {
        height: calc(100vh - 284px);
      }
    }
  }
}

.analysisSectionWithLabel {
  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        .ant-table-body {
          height: calc(100vh - 295px);
        }
      }

      .ant-table.ant-table-empty .ant-table-container {
        tbody.ant-table-tbody {
          height: calc(100vh - 275px);
        }
      }
    }
  }
}

.editAnalysisModal {
  .ant-input:disabled {
    background-color: $disabled-color !important;
    color: $inactive-status-color !important;
  }

  .ant-picker-disabled {
    background-color: $disabled-color !important;
    color: $inactive-status-color !important;
  }

  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: $disabled-color !important;
    color: $inactive-status-color !important;
  }
}
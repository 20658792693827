@use '../../../common/styleConstants.scss' as *;

.geneticsGlobal {
  width: 100%;
  margin-top: -15px;
  padding: 0 15px;

  .table :global {
    .ant-table .ant-table-container {
      .ant-table-body {
        height: calc(100vh - 204px);
      }
    }

    .ant-table.ant-table-empty .ant-table-container {
      tbody.ant-table-tbody {
        height: calc(100vh - 204px);
      }
    }
  }

  .mobileDropdownActions,
  .dropdownActions {
    margin-top: -2px;
    margin-bottom: -2px;
    margin-left: -10px;
    color: $bright-gray;
    float: right;

    visibility: hidden;
    opacity: 0;
    transition: 0.3s;

    i {
      border-radius: 10px;
    }

    &:focus i {
      background-color: $nickel;
    }

    &:not(:active) i {
      background-color: transparent;
    }
  }

  .row:hover .dropdownActions {
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
  }
}

.menuItemOptions {
  span {
    display: flex;
    align-items: center;

    i::before {
      margin-right: 4px;
    }
  }

  .icon {
    color: $top-bar-icon;
  }
}
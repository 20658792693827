@use '../../common/styleConstants.scss' as *;

.xlsxReportModal {
  width: 720px !important;
}

.stockingChartModal {
  width: 100% !important;
  padding: 20px;

  .ant-modal-content {
    width: 100%;
    left: 0 !important;

    .ant-modal-body {
      padding: 0 0 15px;
      min-height: calc(100vh - 94px);
    }

    .ant-modal-footer {
      display: none;
    }
  }
}

.growDeltaModal {
  width: 100% !important;
  padding: 20px;

  .ant-modal-content {
    width: 100%;
    left: 0 !important;

    .ant-modal-body {
      padding: 0 0 15px;
      min-height: calc(100vh - 94px);
    }

    .ant-modal-footer {
      display: none;
    }
  }

  @media (max-width: 1199px) {
    .ant-modal-content {
      height: 135vh !important;
    }
  }

  @media (max-width: 768px) {
    .ant-modal-content {
      height: 140vh !important;
    }
  }
}

.search {
  .ant-input-group {
    .ant-input {
      color: $title-color-dark;
      line-height: 1.8 !important;
    }

    .ant-input-suffix {
      .ant-input-clear-icon {
        color: $title-color-dark;
      }
    }

    .ant-input-group-addon {
      .ant-btn {
        border: none !important;
        color: $title-color-dark !important;
      }
    }
  }

  .ant-input-search-button {
    background-color: $body-background-dark;
    padding: 0 8px;
    margin-left: 1px;

    i {
      font-size: 1em;
    }
  }
}

.settingStockingModal {
  .ant-image {
    display: flex;
  }
}

.ant-form input[type="file"] {
  width: 100%;
}

.newStockingModal,
.settingStockingModal {
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 8px 0px !important;
  }
}

.stockingsTable {
  .ant-table-container .ant-table-body {
    height: calc(100vh - 260px);
  }

  .ant-table-pagination {
    margin-top: 20px;
  }

  @media (max-width: 500px) {
    .ant-table-container .ant-table-body {
      height: calc(100vh - 274px);
    }
  }

  .ant-spin-nested-loading>div>.ant-spin {
    max-height: unset;
  }

  .ant-spin-blur::after {
    opacity: 0;
  }

  .ant-table-container .ant-table-content .ant-table-tbody .ant-empty.ant-empty-normal {
    margin: 0px;
    height: calc(100vh - 250px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@use '../../common/styleConstants.scss' as *;

.users {
  width: 100%;
  margin-top: -15px;
  padding: 0 15px;

  .rowFlex {
    display: flex;
    flex-direction: column;
    flex: 1;

    // .tableUsers :global {
    //   .ant-table .ant-table-container .ant-table-body {
    //     height: calc(100vh - 235px);
    //     overflow-y: scroll;
    //   }
    // }

    .tableUsers :global {
      .ant-table .ant-table-container {
        .ant-table-body {
          height: calc(100vh - 235px);

          table {
            display: table;
          }
        }
      }

      .ant-table.ant-table-empty .ant-table-container {
        tbody.ant-table-tbody {
          height: calc(100vh - 235px);
        }
      }
    }
  }
}

.searchOutlinedIconDark svg {
  fill: $title-color-dark;
}

.searchOutlinedIconDark:hover svg {
  fill: $chinese-silver;
}

.searchOutlinedIconLight svg {
  fill: $silver-chalice;
}

.searchOutlinedIconLight:hover svg {
  fill: $title-color-light;
}

.menuSearch {
  background-color: $body-background-light;
  padding: 8px;

  .inputSearch {
    width: 188px;
    margin-bottom: 8px;
    display: block;
  }

  .buttonSearch {
    width: 90px;
    border-radius: 5px;
  }

  .buttonReset {
    border: 1px solid $title-color-light;
    background-color: transparent;
    border-radius: 5px;
    color: $title-color-light;
    width: 90px;

    &:hover {
      background-color: $chinese-white;
    }
  }
}

.editUserModal {
  display: flex;
  flex-direction: column;
  flex: 1;

  .contentHeader {
    padding: 15px 0px;
    color: $title-color-dark;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .title {
      font-family: $font-family;
      font-weight: 500;
      color: $sonic-silver;
      font-size: 1.4em;
    }

    .switch {
      color: $title-color-light;
      display: grid;
      grid-column-gap: 10px;
      grid-template-columns: auto auto;
      float: right;
    }
  }

  @media (max-width: 860px) {
    .contentHeader {
      flex-direction: column;

      div {
        margin-bottom: 2px;
      }
    }
  }

  .selectOptions {
    margin-left: 15px;
    width: 120px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-family: $font-family;
      font-weight: bold;
      font-size: 1.6em;
    }
  }

  .button {
    float: right;
  }
}

.resetPasswordModal {
  .btnGeneratePassword {
    border: 1px solid $title-color-light;
    background-color: transparent;
    border-radius: 5px;
    color: $title-color-light;

    &:hover {
      background-color: $chinese-white;
      border: 1px solid $title-color-light;
      color: $title-color-light;
    }
  }
}

.selectedRow {
  background-color: $rich-black;
}

.userData {
  overflow-x: hidden;
  overflow-y: auto;
}

.label {
  font-size: 14px;
}

.keepOpen {
  display: flex;
  justify-content: flex-start !important;
  padding-left: 5px;
}

.changeOwner {
  color: $sonic-silver;
}

.statusCell {
  display: flex;
  width: 100%;
  margin-top: -5px;
  margin-bottom: -5px;
}

.optionsCell {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.menuItemOptions {
  span {
    display: flex;
    align-items: center;

    i::before {
      margin-right: 2px;
    }
  }

  .icon {
    color: $top-bar-icon;
  }
}
@use '../../../src/common/styleConstants.scss' as *;

.analysisWithoutStocking {
  width: 100%;
  margin: -15px 10px 0 10px;

  .title {
    font-family: $font-family;
    font-weight: bold;
    font-size: 1.6em;
  }
}

.confirmModal {
  width: 100%;

  .description {
    font-size: 14px;
  }
}

.table {
  margin-top: 16px;

  .tags {
    text-align: right;
  }

  .consolidated {
    background-color: $white;
    border: 1px solid $chinese-silver;
    text-shadow: none;
    color: $title-color-light;
  }

  .analysisRow {
    visibility: visible;
    opacity: 1;
    transition: all 0.5s ease-in-out;

    .tagsCell {
      justify-content: flex-end;
      width: 100%;

      .eyeIcon {
        margin-left: 2px;
      }

      .icon {
        color: $white;

        &:hover {
          color: inherit;
        }

        i {
          color: $white;

          &:hover {
            color: inherit;
          }
        }
      }
    }

    &:hover {
      .icon {
        color: inherit;

        i {
          color: inherit;
        }
      }
    }
  }

  .selectedRow .showAnalysis {
    visibility: visible;
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }

  .selectedRowDark {
    background-color: $denim-blue !important;
  }

  .selectedRowLight {
    background-color: $denim-blue !important;

    td {
      color: $white !important;
    }
  }

  .showAnalysisDark {
    background-color: $denim !important;
  }

  .showAnalysisLight {
    background-color: $true-blue !important;

    td {
      color: $white !important;
    }
  }

  :global {
    .ant-table .ant-table-container .ant-table-body {
      height: calc(100vh - 195px);
    }

    .ant-table.ant-table-empty .ant-table-container {
      tbody.ant-table-tbody {
        height: calc(100vh - 195px);
      }
    }
  }
}

.lightAnalysisCode {
  color: $title-color-light;

  &:hover {
    color: $primary-color-light;
  }
}

.darkAnalysisCode {
  color: $title-color-dark;

  &:hover {
    color: $primary-color-dark;
  }
}

.menuItemOptions {
  span {
    display: flex;
    align-items: center;

    i {
      margin-right: 2px !important;
    }
  }
}

.warningIcon {
  svg {
    color: $dark-orange;
  }
}
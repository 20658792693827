@use '../../../common/styleConstants.scss' as *;

:global {

  .ant-table.ant-table-middle .ant-table-title,
  .ant-table.ant-table-middle .ant-table-footer,
  .ant-table.ant-table-middle .ant-table-thead>tr>th,
  .ant-table.ant-table-middle .ant-table-tbody>tr>td,
  .ant-table.ant-table-middle tfoot>tr>th,
  .ant-table.ant-table-middle tfoot>tr>td {
    padding: 4px 8px;
  }

  .ant-table {
    background-color: transparent;
  }

  .ant-table table {
    border-collapse: collapse;
  }

  .ant-spin-blur::after {
    opacity: 0;
  }

  .ant-table-body::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: transparent;
  }

  .ant-table.ant-table-empty .ant-table-tbody {
    .ant-empty.ant-empty-normal {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none;
  }

  .ant-table-tbody>tr>td {
    border: none;
  }

  .ant-table-tbody>tr>td:empty::before {
    content: "-";
  }

  .ant-table-tbody .ant-table-placeholder :hover td {
    background-color: transparent;
  }

  .ant-spin-nested-loading>div>.ant-spin {
    max-height: unset;
    height: calc(100% - 30px);
  }

  .ant-pagination-options {
    .ant-select-dropdown {
      background-color: $body-background-light;

      .rc-virtual-list .rc-virtual-list-holder .rc-virtual-list-holder-inner {
        .ant-select-item.ant-select-item-option {
          transition: 0s;
          background-color: $body-background-light;

          .ant-select-item-option-content {
            color: $dark-charcoal-3;
          }
        }

        .ant-select-item.ant-select-item-option.ant-select-item-option-active:hover {
          background-color: $dropdown-hover-color-light;
        }

        .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
          background-color: $body-background-light;
        }
      }
    }
  }
}

.lightTable {
  :global {
    .ant-table-body::-webkit-scrollbar-thumb {
      background-color: $argent;
    }

    .ant-table-body::-webkit-scrollbar-thumb:hover {
      background-color: $table-thead;
    }

    .ant-table-body::-webkit-scrollbar-thumb:hover {
      background-color: $table-thead;
    }

    .ant-table-thead .ant-table-cell {
      font-size: 12px;
      background-color: transparent;
      color: $sonic-silver;
      font-weight: 500;
      border: none;
      text-transform: uppercase;

      .ant-table-column-title {
        font-size: 12px;
      }
    }

    .ant-table-tbody .ant-table-cell {
      font-size: 14px;
      color: $title-color-light;
      background-color: unset;

      div {
        span {
          font-size: 14px;
        }

        div {
          font-size: 14px;
        }
      }
    }

    .ant-table-tbody .ant-table-placeholder td:hover {
      background-color: transparent;
    }

    .ant-table-tbody .ant-table-row:hover .ant-table-cell {
      background-color: $row-selected-light;
      color: $title-color-light !important;
      cursor: pointer;
    }

    .ant-table-tbody>tr:nth-child(even) {
      background-color: $even-row-background-light;
    }

    .ant-table-tbody>tr:nth-child(odd) {
      background-color: $odd-row-background-light;
    }

    .ant-pagination {
      display: flex;
      align-items: center;

      .ant-pagination-prev,
      .ant-pagination-next {
        i {
          position: relative;
          top: -2px;
          color: $davys-grey-5;
        }
      }
    }

    .ant-pagination .ant-pagination-prev,
    .ant-pagination .ant-pagination-next {
      margin: 0;
      transition: 0s;
      background-color: $body-background-light;
      border: 1px solid $davys-grey-5;
    }

    .ant-pagination-item {
      margin: 0;
      background-color: $body-background-light;
      border: 1px solid $davys-grey-5;

      &:hover {
        border: 1px solid $davys-grey-5;
        background-color: $btn-bg-hover-light;
      }
    }

    .ant-pagination-item-ellipsis {
      font-size: 10px;
      color: $chinese-silver;
    }

    .ant-pagination-jump-next,
    .ant-pagination-jump-prev {
      transition: 0s;
      margin: 0;
      border-radius: 0;
      border-top: 1px solid $davys-grey-5;
      background-color: $body-background-light;
      border-bottom: 1px solid $davys-grey-5;
    }

    .ant-pagination-jump-next.ant-pagination-jump-next-custom-icon {
      border-bottom: 1px solid $davys-grey-5;
    }

    .ant-pagination.ant-table-pagination.ant-table-pagination-right .ant-pagination-next .ant-pagination-item-link,
    .ant-pagination.ant-table-pagination.ant-table-pagination-right .ant-pagination-prev .ant-pagination-item-link {
      transition: 0s;
      margin: 0 !important;
      border-radius: 5px;
      align-items: center;
      background-color: $body-background-light;
      border: none;

      span {
        svg {
          color: $davys-grey-5;
        }
      }
    }

    .ant-pagination .ant-pagination-item {
      border-radius: 0px;
      border-left: none;
      border-right: none;

      a {
        color: $davys-grey-5;
      }
    }

    .ant-pagination .ant-pagination-item-active {
      background-color: $primary-color-light;
      border-color: $primary-color-light;

      a {
        color: $white;
      }
    }

    .ant-pagination .ant-pagination-prev {
      border-radius: 5px 0px 0px 5px;
      border-right: none;

      &:not(.ant-pagination-disabled):hover {
        background-color: $btn-bg-hover-light;
      }
    }

    .ant-pagination .ant-pagination-next {
      transition: 0s;
      border-radius: 0px 4px 4px 0px;
      border-left: none;

      &:not(.ant-pagination-disabled):hover {
        background-color: $btn-bg-hover-light;
      }
    }

    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
      transition: 0s;
      background-color: transparent;
      border-color: $davys-grey-5;
    }

    .ant-pagination.ant-pagination-mini {
      .ant-pagination-item:not(.ant-pagination-item-active) {
        background-color: $body-background-light;
        border-color: $davys-grey-5;
      }
    }

    .ant-pagination-options {
      .ant-select .ant-select-selector {
        border: 1px solid $pagination-border-light;
        background-color: $pagination-background-light;
        transition: 0s;

        span {
          color: $title-color-light;
          transition: 0s;
        }
      }
    }

    .ant-table.ant-table-empty {
      .ant-table-tbody {
        tr .ant-table-cell {
          background-color: $body-background-light;
        }

        tr:hover .ant-table-cell {
          background-color: $body-background-light;
        }

        .ant-empty.ant-empty-normal {
          .ant-empty-description {
            color: $sonic-silver;
          }

          .ant-empty-image {
            path {
              fill: #dce0e6;
            }

            .ant-empty-img-simple-ellipse {
              fill: #dce0e6;
            }

            .ant-empty-img-simple-path {
              fill: $davys-grey-5;
            }
          }
        }
      }
    }

    .ant-table-row-expand-icon {
      background-color: $body-background-light;
      border-color: currentColor;
    }
  }
}

.darkTable {
  :global {
    .ant-table-body::-webkit-scrollbar-thumb {
      background-color: $table-title;
    }

    .ant-table-body::-webkit-scrollbar-thumb:hover {
      background-color: $table-thead;
    }

    .ant-table-thead .ant-table-cell {
      font-size: 12px;
      background-color: transparent;
      color: $table-title;
      font-weight: 500;
      border: none;
      text-transform: uppercase;

      .ant-table-column-title {
        font-size: 12px;
      }
    }

    .ant-table-tbody .ant-table-cell {
      font-size: 14px;
      color: $bright-gray;
      background-color: unset;

      div {
        span {
          font-size: 14px;
        }

        div {
          font-size: 14px;
        }
      }
    }

    .ant-table-tbody .ant-table-placeholder td:hover {
      background-color: transparent;
    }

    .ant-table-tbody .ant-table-row:hover .ant-table-cell {
      background-color: $row-selected-dark;
      cursor: pointer;
    }

    .ant-table-tbody>tr:nth-child(even) {
      background-color: $even-row-background-dark;
    }

    .ant-table-tbody>tr:nth-child(odd) {
      background-color: $odd-row-background-dark;
    }

    .ant-pagination {
      display: flex;
      align-items: center;

      .ant-pagination-prev,
      .ant-pagination-next {
        transition: 0s;
        padding: 0;
        margin: 0px;
        background-color: $pagination-background-dark;
        border: 1px solid $pagination-border-dark;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          align-self: center;
          color: $chinese-silver;
        }

        &:not(.ant-pagination-disabled):hover {
          background-color: $btn-bg-hover;
        }
      }

      .ant-pagination-prev {
        border-radius: 5px 0px 0px 5px;
        border-right: none;
      }

      .ant-pagination-next {
        transition: 0s;
        border-radius: 0px 5px 5px 0px;
        border-left: none;

        .ant-pagination-item-link {
          border-radius: 0px 5px 5px 0px;
        }
      }

      .ant-pagination-item {
        margin-right: 0px;
        color: $bright-gray;
        background-color: $pagination-background-dark;

        border: 1px solid $pagination-border-dark;
        border-radius: 0px;
        border-left: none;
        border-right: none;

        a {
          font-weight: 500;
          color: $bright-gray;
          font-size: 14px;
        }

        &:hover {
          background-color: $btn-bg-hover;
          border-color: $pagination-border-dark;
          border-left: none;
          border-right: none;
        }
      }

      .ant-pagination-item.ant-pagination-item-active {
        color: $bright-gray;
        border-radius: 0px;
        background-color: $primary-color-dark;
      }
    }

    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      transition: 0s;
      border: 1px solid $pagination-border-dark;
      background-color: $pagination-background-dark;
      border-radius: 0px;
      border-left: none;
      border-right: none;
      margin: 0;

      .ant-pagination-item-ellipsis {
        color: $title-color-dark;
        font-size: 10px;
      }
    }

    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
      color: $primary-color-dark;
    }

    .ant-pagination-disabled .ant-pagination-item-link,
    .ant-pagination-disabled:hover .ant-pagination-item-link {
      border: $pagination-border-dark;
      border-radius: 5px;
      background-color: $cetacean-blue;
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      transition: 0s;
      background-color: $cetacean-blue;
      border: $pagination-border-dark;
    }

    .ant-pagination-disabled {
      svg {
        fill: $title-color-dark;
      }
    }

    .ant-pagination-options {
      .ant-select .ant-select-selector {
        border: 1px solid $pagination-border-dark;
        background-color: $pagination-background-dark;
        transition: 0s;

        span {
          transition: 0s;
          color: $title-color-dark;
        }
      }
    }

    .ant-table.ant-table-empty {
      .ant-table-tbody {
        tr .ant-table-cell {
          background-color: $body-background-dark;
        }

        tr:hover .ant-table-cell {
          background-color: $body-background-dark;
        }

        .ant-empty.ant-empty-normal {
          .ant-empty-description {
            color: $title-color-dark;
          }

          .ant-empty-image {
            path {
              fill: transparent;
            }

            .ant-empty-img-simple-ellipse {
              fill: $body-background-light;
            }

            .ant-empty-img-simple-path {
              fill: #fafafa;
            }
          }
        }
      }
    }
  }
}

.lrvTableSpinner {
  margin-top: 35px;

  .spinnerContainer {
    position: relative;

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9;

      i {
        font-size: 40px;
      }
    }
  }
}

.spinnerBackgroundDark {
  background-color: $body-background-dark;
}

.spinnerBackgroundLight {
  background-color: $body-background-light;
}
@use '../../common/styleConstants.scss' as *;

.createModuleModal,
.editModuleModal {
  .ant-input:disabled {
    background-color: $disabled-color !important;
    color: $inactive-status-color !important;
  }

  .ant-picker-disabled {
    background-color: $disabled-color !important;
    color: $inactive-status-color !important;
  }

  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: $disabled-color !important;
    color: $inactive-status-color !important;
  }

  .ant-input-number-disabled {
    background-color: $disabled-color !important;
    color: $inactive-status-color !important;
  }

  .ant-input-affix-wrapper-disabled {
    background-color: $disabled-color !important;
    color: $inactive-status-color !important;
  }
}

#tableModules {
  .ant-table-body {
    height: calc(100vh - 240px);
  }

  &.ant-table-empty .ant-table-container {
    tbody.ant-table-tbody {
      height: calc(100vh - 240px);
    }
  }
}
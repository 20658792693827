@use '../../common/styleConstants.scss' as *;

.formBatchContainer {
  .ant-form-item-explain-error {
    div {
      font-size: 12px;
    }
  }

  .from {
    .ant-form-item-explain-error {
      div {
        font-size: 9px !important;
      }
    }
  }

  .to {
    .ant-form-item-explain-error {
      div {
        font-size: 9px !important;
      }
    }
  }
}

.referenceCurveModal,
.editCampusModal {
  .ant-input:disabled {
    background-color: $disabled-color !important;
    color: $inactive-status-color !important;
  }

  .ant-picker-disabled {
    background-color: $disabled-color !important;
    color: $inactive-status-color !important;
  }

  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: $disabled-color !important;
    color: $inactive-status-color !important;
  }

  .ant-input-number-disabled {
    background-color: $disabled-color !important;
    color: $inactive-status-color !important;
  }

  .ant-input-affix-wrapper-disabled {
    background-color: $disabled-color !important;
    color: $inactive-status-color !important;
  }
}

#tableCampus {
  .ant-table-body {
    height: calc(100vh - 235px);
  }

  &.ant-table-empty .ant-table-container {
    tbody.ant-table-tbody {
      height: calc(100vh - 235px);
    }
  }
}